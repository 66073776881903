export default [
  'for',
  'and',
  'of',
  'in',
  'see',
  'a',
  'both',
  'le',
  'de',
  'du',
  'the',
  'fixerup',
  'to',
  'us',
  'ize',
  'no',
  's',
  'all',
  'son',
  'how',
  'does',
  'not',
  'via',
  'one',
  'cf',
  'many',
  'this',
  'these'
];
